import * as React from "react";
import Layout from "../components/Navigation/layout";

// styles
const pageStyles = {
    color: "#232129",
    padding: "96px",
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
    marginTop: 0,
    marginBottom: 64,
    maxWidth: 320,
}

const paragraphStyles = {
    marginBottom: 48,
}
const codeStyles = {
    color: "#8A6534",
    padding: 4,
    backgroundColor: "#FFF4DB",
    fontSize: "1.25rem",
    borderRadius: 4,
}

function errorPage() {
    const currentPath = window.location.pathname;

    if (currentPath.startsWith("/Unbekannt/")) {
        window.location.href = "https://fairstaerkt.at/marktplatz/?";
    } else if (currentPath.startsWith("/haendleruebersicht/")) {
        window.location.href = "https://www.fairstaerkt.at/haendleruebersicht/";
    } else {
        return(
            <Layout pageTitle={"404 - not found"} description={"Error Page"} >
                <div className={"d-flex justify-content-center align-items-center"} style={{minHeight: '200px'}}>
                    <h4>
                        Die von dir gesuchte Seite wurde leider nicht gefunden 🙁
                    </h4>
                </div>
            </Layout>
        )
    }
}


export default errorPage;
